@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.swiper {
    width: 100%;
    height: 100%;
    background-color: #000;

  }
  
  .swiper-slide {
    font-size: 18px;
    color: #ffffff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
  }
  
  .swiper-slide .title {
    font-family: Inter;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #000000;
    text-align: center;
    padding: 150px 0 20px 0;
    font-size: 60px;
    font-weight: 700;
  }
  
  .swiper-slide .subtitle {
    font-family: Inter;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #000000;
    text-align: center;
    font-size: 50px;
    font-weight: 700;
  }
  
  .swiper-slide .text {
    font-family: Inter;
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: #000000;    
    text-align: center;
    font-size: 40px;
    padding: 10px 100px;
    line-height: 1.3;
  }
  
  .mtk{
    font-size: 40px;
    font-weight: 700;
  }





  @media (max-width: 992px){
    .swiper-slide{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }
    .swiper-slide .text{
      padding: 5px;
    }
    .swiper-slide .title {
      font-size: 50px;

    }
    .mtk{
      font-size: 45px;
      font-weight: 700;
    }
    .swiper-slide .subtitle {

      font-size: 40px;

    }
    
    .swiper-slide .text {

      font-size: 35px;

    }
    .work{
      font-size: 28px;
    }
    .textorange{

      font-size: 28px;
    }

    :root {
      --swiper-navigation-size: 35px;
    }
    .text p {margin: 0 50px;}
  }




  @media (max-width: 768px){
    .swiper-slide{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }
    .swiper-slide .title {
      font-size: 45px;
      padding: 50px 0 20px 0;

    }
    .mtk{
      font-size: 40px;
      font-weight: 700;
    }
    .swiper-slide .subtitle {

      font-size: 35px;

    }
    
    .swiper-slide .text {

      font-size: 30px;

    }

    .work{
      font-size: 24px;
    }
    .textorange{

      font-size: 24px;
    }

  }


  @media(max-width: 565px) {
    .swiper-slide{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }

    .swiper-slide .title {
      font-size: 35px;

    }
    .mtk{
      font-size: 32px;
      font-weight: 700;
    }
    .swiper-slide .subtitle {

      font-size: 27px;

    }
    
    .swiper-slide .text {

      font-size: 27px;

    }

  }



  @media (max-width: 320px){
    .swiper-slide{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }

    .swiper-slide .title {
      font-size: 30px;
      margin-top: 50px;

    }
    .mtk{
      font-size: 27px;
      font-weight: 700;
    }
    .swiper-slide .subtitle {

      font-size: 24px;

    }
    
    .swiper-slide .text {

      font-size: 12px;

    }



  }
