@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
margin: 0;
padding: 0;
scroll-behavior: smooth;
}
a{
  text-decoration: none; 
  color: inherit;
}


header{
  position: fixed;
  top: 0;
  font-family: Inter;
  background-color: #1E2227;
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 2px solid #F36423 ;
  
  z-index: 3;
  
  
}



.wrapper{
  width: 100%;

}

.header{

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px;
  justify-content: space-around;;
}



body{
  background-color: #F0F0F0;
  color: #D9D9D9;

}

.header img{
  margin: 0 25px;
}

.header__nav-list{

  font-size: 24px;
  display: flex;
  gap: 60px;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  list-style: none;
}


.info{
  max-width: 1920px;
  background-color: #1E2227;
  height: 913px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.swip{
  width: 100%;
  height: 100%;
}

.ProdactHead{
  
  width: 100%;
  height: 100px;
}

.product{
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #D9D9D9;
  flex-direction: column;
  align-items: center;
  

}

.producthead{
  font-family: Inter;
  font-size: 32px;
  color: #F36423;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-image: url(/src/components/img/productheadbg.png);
  border-top: 2px  solid #F36423 ;
  border-bottom: 2px  solid #F36423 ;
}


.delivery{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #D9D9D9;
  border-bottom: 2px  solid #F36423 ;
  
}

.deliveryhead{
  font-family: Inter;
  font-size: 32px;
  color: #F36423;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-image: url(/src/components/img/delivery.png);
  border-top: 2px  solid #F36423 ;
  border-bottom: 2px  solid #F36423 ;
}

.deliveryinfo{
  font-family: Inter;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
}



.deliveryinfotext{
  margin: 50px;
  gap: 20px;
  font-size: 35px;
  color: #3C4451;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.work{
  font-family: Inter;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3C4451;
  font-size: 34px;
  text-align: center;
}
.textorange{
  font-weight: 600;
  color: #F36423;
}

.card{
  font-family: Inter;
  background-image: url(/src/components/img/bg4.png);
  width: 100%;
  min-height: 970px;
  display: flex;
  flex-direction: column;
  background-size: cover;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: center;
}


.cardh2{
  font-size: 36px;
  margin: 50px 0;
  text-align: center

}

.table{
  margin: 20px 0 50px 0;
  width: 50%;
  border-collapse: collapse;
  
  

}

.table td {
  font-size: 20px;
  margin: 0;
  text-align: center;
  padding: 10px;
  border: 2px solid #F36423;
  text-align: center;
}





footer{
  width: 100%;
  height: 100px;
  background-color: #1E2227;
  border-top: 2px  solid #F36423 ;
  display: flex;
  justify-content: center;

}



.shopCart{
  width: 350px;
  height: 450px;
  background-color: #1E2227;
  border-radius: 10px;
  border: 2px solid #F36423;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  transition: transform 1000ms ease;
  text-align: center
}

.shopCart:hover{
  transform: scale(1.1);
}

.shop{
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
}

.position{
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 100%;
  justify-content: space-evenly;
  align-content: space-around;
  flex-wrap: wrap;
  white-space: pre-line;  
  vertical-align: bottom;
  margin: 25px 0;
}

.shopCart img {
  padding: 20px;
  width: 50%;

}

.shopCart h2{
  font-size: 18px;
  padding: 5px 20px 5px 20px;

}

.shopCart p{
  
  padding: 0 15px 0 15px;
  
}

.shopCart b{
  padding: 10px;


}
.textPosition{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}



.header__menu-button {
  display: none;
}


.footertext{
  font-size: 20px;
  text-align: center;
  display: flex;
  width: 80%;
  justify-content: space-around;
  align-items: center;
}

.contact{
  
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.contact a {
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

@media (max-width: 1576px){
  .deliveryinfo{
    margin-top: 20px;

  }
  .work{
    margin: 20px 0;
      
  }
  .shopCart{
    width: 250px;
    height: 350px;
  }
  .deliveryinfotext{
    font-size: 25px;
  }
  .delivery {
    height: auto;
}


}

@media (max-width: 992px){
  .header__menu-button{
    display: flex;
    margin: 0 25px;
  }
  .header__nav{
    
    position: fixed;
    top: 0;
    left: -101%;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 2px solid #F36423;
    transition: transform 0.3s linear;

  }

  .header__nav.active{
    transform: translateX(100%);
  }

  .header__nav-list{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1E2227;
    

  }
  .header__nav-item{
    margin: 10px 0;
    color: #F36423;
    font-weight: 500;
  }

  .deliveryinfotext{

    margin: 0;
  }



  .navigator{
    font-size: 20px;
    gap: 50px;
  }
  .product{
    height: auto;
  }
  .delivery{
    height: auto;
  }
  .navigator{
    font-size: 16px;
  }

}

@media (max-width: 768px){



  .deliveryinfotext{
    font-size: 20px;
    margin: 0;
  }
  .deliveryinfo img{
    width: 350px;
    height: 100%;

  }
  .product{
    height: auto;
  }
  .delivery{
    height: auto;
  }



}

@media(max-width: 565px) {
  .deliveryinfotext{
    font-size: 14px;
    gap: 20px;
    
  }
  .deliveryinfo img{
    width: 150px;
    height: auto;
  }
  .table td{
    font-size: 12px;
  }
  .deliveryinfotext {
    margin: 0;
  }
  .footertext{
    font-size: 15px;
  }
  .cardh2{
    font-size: 30px;
  }
}

@media (max-width: 320px){
  .product{
    height: auto;
  }
  .shopCart{
    width: 200px;
    height: 300px;
  }
  .shopCart img{
    width: 30%;
  }
  .info{
    height: auto;
  }
  .producthead{
    font-size: 16px;
  }
  .deliveryhead{
    font-size: 16px;
  }
  .cardh2{
    font-size: 16px;
  }
  .work{
    font-size: 16px;
  }
  .textorange{
    font-size: 14px;
  }
  .deliveryinfotext{
    font-size: 14px;
  }
  .delivery{
    height: auto;
  }
  .deliveryinfo img{
    display:none;
  }
  .table td{
    font-size: 12px;
  }
  .footertext{
    width: 100%;
    font-size: 12px;
    gap: 10px;
  }
}


.header__menu-button{
  position: relative;
  z-index: 50;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
  height: 18px;
}

.header__menu-button span{
  height: 2px;
  width: 80%;
  background-color: aqua;
}

.header__menu-button::after, .header__menu-button::before {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: aqua;
  transition: all 0.3s ease 0s;
}


.header__menu-button::before {top: 0}

.header__menu-button::after{bottom: 0;}


.header__menu-button.active span {transform: scale(0);}

.header__menu-button.active::after{
  bottom: 50%;
  transform: rotate(45deg) translate(0, 50%);
}

.header__menu-button.active::before{
  top: 50%;
  transform: rotate(-45deg) translate(0, -50%);
}

@keyframes burgerAnimation {
  from{opacity: 0;}
  to{opacity: 1;}
}